
.goods-analysis {
    .title-panel {
        position: relative;
        padding: 0 20px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:after {
            box-sizing: border-box;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid hsla(0,0%,48.2%,.1);
        }
        .item-txt {
            height: 100%;
            .item-txt-content {
                font-size: 16px;
                font-weight: 500;
                color: #ff7752;
                position: relative;
                height: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                &:after {
                    box-sizing: border-box;
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    width: 56px;
                    height: 2px;
                    background: #ff7752;
                    border-radius: 2px;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .overview-wrapper {
        position: relative;
        padding: 30px 20px 0 20px;
        display: flex;
        flex-wrap: wrap;
        &:after {
            pointer-events: none;
            box-sizing: border-box;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid #F5F5F5;
        }
        .overview-item {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 30px;
            .manual-title {
                font-size: 14px;
                font-weight: 400;
                color: #666;
            }
            .align-items-num {
                margin: 10px 0 5px 0;
                display: flex;
                align-items: flex-end;
                .number-value {
                    font-size: 22px;
                    color: #333;
                    font-weight: 500;
                    line-height: 100%;
                }
            }
            .overview-label {
                align-self: flex-start;
                padding: 2px;
                background: #fbfbfb;
                border-radius: 2px;
                font-size: 12px;
                font-weight: 400;
                color: #999;
            }
        }

    }
    .category-wrapper {
        margin-top: 20px;
        padding: 0 20px;
    }
    .echarts-wrapper {
      margin-top: 30px;
        width: 100%;
        height: 308px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        .echarts-item {
            flex: 1;
            height: 100%;
        }
    }
}

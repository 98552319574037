
.goods-analysis {
    font-size: 14px;
    .goods-trend-box {
        .chart-title {
            height: 48px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          span {
            margin-right: 5px;
          }
        }
        .tab-header-switch {
            box-sizing: border-box;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .echarts-data-picture {
            div {
              width: 100%;
              height: 370px;
            }

        }
    }

}


.video-container{
    display: flex;
    justify-content: start;
    height: 100%;
    .video{
        height: 100%;
        flex: 1;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        .video-content{
            background-color: #fff;
            margin-bottom:20px;
            padding: 15px 20px;
            .video-item{
                height: 100%;
                border-bottom: none;
                align-items: inherit;
                display: flex;
                .video-work{
                    display: flex;
                    flex: 1;
                    .videoImg{
                        width: 105px;
                        height: 130px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            max-width:100%;
                            max-height:100%;
                        }
                    }
                    .video-right{
                        margin-left: 20px;
                        .title{
                            align-items: inherit;
                            padding-bottom: 0;
                            max-width: 430px;
                            .video-title{
                                color: #333;
                                font-size: 16px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                white-space: inherit;
                            }
                        }
                        .like{
                            display: flex;
                            align-items: center;
                            .span-hover{
                                display: flex;
                                align-items: center;
                                padding: 10px 20px 0 0;
                                color: #999;
                                i{
                                    font-size:18px;
                                    color: #999;
                                    padding: 0 6px 0 0;
                                }
                            }
                        }
                        .tag{
                            display: flex;
                            margin-top: 10px;
                            .tag-title {
                                display: inline-block;
                                min-width: 38px;
                                font-size: 12px;
                                font-weight: 400;
                                color: #999;
                            }
                            .tag-list{
                                display: inline-block;
                                max-height: 56px;
                                overflow: hidden;
                                margin-bottom: 10px;
                                span{
                                    padding: 0 10px;
                                    background: rgba(244,244,244,1);
                                    border-radius: 12px;
                                    display: inline-flex;
                                    margin:0 10px 6px 0;
                                }
                            }
                        }
                        .publish-time{
                            color: #999;
                        }

                    }
                }
                .handel{
                    display: flex;
                    flex-direction: column;
                    button{
                        width: 106px;
                        height: 32px;
                        color: #fff;
                        background:linear-gradient(85deg,rgba(255,0,0,1),rgba(255,96,96,1));
                        border-radius:2px;
                        border: none;
                        cursor: pointer;
                        margin-bottom: 10px;
                        &:hover{
                            background:linear-gradient(85deg,rgba(255,96,96,1),rgba(255,0,0,1));
                        }
                        &:focus{
                            outline: none;
                        }
                    }
                }

                .video-item-null{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    width: 1%;
                    span{
                        /*min-height: 130px;*/
                        line-height: 130px;
                    }
                }
            }
        }
        ::v-deep.el-tabs{
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
            flex: 1;
        }
        ::v-deep.el-tabs__header{
            border-bottom: none;
            margin: 0;
        }
        ::v-deep.el-tabs__content{
            flex: 1;
            background: #fff;
            padding-top: 20px;
            min-height: 668px;
        }
        ::v-deep.el-tab-pane{
            height: 100%;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
            background: #fff;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item{
            border-left: none;
        }
        ::v-deep.el-tabs__header .is-active {
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            color: #0824ce;
            border-radius: 6px 6px 0 0;
        }
        ::v-deep.el-tabs__item{
            height: 52px;
            line-height: 52px;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
            border: none;
        }
        ::v-deep.el-tabs__item:hover {
            color: #0824ce;
            cursor: pointer;
        }
    }
}


.video-detail{
    display: flex;
    flex-direction: column;
    padding: 0 25px 25px;
    .video-chart{
        height: 100%;
        overflow: auto;
        flex: 1;
        padding-right: 10px;
        .header-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .avatar {
                display: flex;
                justify-content: start;
                img {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                    border-radius: 50%;
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51,51,51,1);
                    .text{
                        color: #333;
                        font-size:14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        white-space: inherit;
                        line-height: 1.5;
                    }
                }
            }
            .video-info{
                .like-item {
                    list-style-type: none;
                    display: flex;
                    justify-content: start;
                    padding: 0;
                    margin: 0;
                    li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0 20px;
                        border-right: 1px solid #EAEAEA;
                        &:last-child {
                            border-right: none;
                        }
                        span{
                            line-height:1.5;
                        }

                    }
                }
            }
        }
    }
    .video-comment {
        display: flex;
        flex-direction: column;
        .comment-echarts {
            margin-top: 30px;
        }
        .title {
            font-size:14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            padding: 20px 0;
            .num {
                color: #aaaaaf;
            }
        }
        .comment-ul{
            flex: 1;
            padding: 0 8px 0 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                display: flex;
                justify-content: start;
                margin-bottom: 15px;
                margin-left: 10px;
                .content {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height:2;
                    .comment-text {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #EAEAEA;
                        padding-bottom: 10px;
                        .like-num {
                            display: flex;
                            align-items: center;
                            i {
                                color: #999;
                                margin-right: 5px;
                            }
                        }

                    }
                    .time{
                        color: #999;
                        font-size:14px;
                    }
                }
            }
        }
    }
    /*暂无数据*/
    .comment-ul-null{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 418px;
    }
    .video-chart-null{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
